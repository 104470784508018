import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import QuizPage from './pages/QuizPage';
import 'antd/dist/antd.css'; // 引入 Ant Design 样式
import './App.css'; // 引入全局样式
// import process from 'process';


const App = () => (
  <Router>
    <Switch>
      <Route path="/" component={QuizPage} />
    </Switch>
  </Router>
);

export default App;
