import React, { useState } from 'react';
import { Card, Input, Button, Modal, Table } from 'antd';
// import axios from 'axios';
import axios from '../axiosConfig';
// import ReactMarkdown from 'react-markdown';
import './QuestionDetail.css';

import { format } from 'sql-formatter';
import confetti from 'canvas-confetti';


const { TextArea } = Input;

const QuestionDetail = ({ question }) => {
  const [sql, setSql] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ result: '', output: '' });

    const [answerModalVisible, setAnswerModalVisible] = useState(false);
    const [answerModalContent, setAnswerModalContent] = useState({ content: '' });

  const handleSubmit = async () => {
    try {
      // 使用baseURL
      const response = await axios.post(`/api/questions/${question.id}/submit`, { sql });

      // const response = await axios.post(`/api/questions/${question.id}/submit`, { sql });
      // const response = await axios.post(`http://127.0.0.1:5002/api/questions/${question.id}/submit`, { sql });
      const result = response.data;

      if (result.result === 'success') {
        setModalContent({
          result: result.data.is_correct ? '正确' : '错误',
          output: result.data.output
        });
          // 当答案正确时，触发烟花效果
          if (result.data.is_correct) {
            confetti({
              particleCount: 200,
              spread: 70,
              origin: { y: 0.6 },
              ticks: 200,
              zIndex: 2000  // 确保这个值高于你页面中任何其他元素的z-index
            });
          }
      } else {
        setModalContent({
          result: 'SQL运行错误',
          output: result.message
        });
      }
      setModalVisible(true);

    } catch (error) {
      console.error('Error submitting the SQL:', error);
      setModalContent({
        result: 'SQL运行错误',
        output: error.message
      });
      setModalVisible(true);
    }
  };

  const showAnswer = async () => {

    const formattedSQL = format(question.answer_sql);

    // alert(formattedSQL);

    setAnswerModalContent({content: formattedSQL});
    // setAnswerModalContent({content: question.answer_sql});
    setAnswerModalVisible(true);
  };

  const handleModalOk = () => {
    setModalVisible(false);
  };

  const handleAnswerModalOk = () => {
      setAnswerModalVisible(false);
  };

  return (
    <Card title={question.title} className="question-detail">
      <div className="question-content">
        {renderContent(question.content)}
      </div>
      <div className="sql-input-container">
        <TextArea
          rows={6}
          value={sql}
          onChange={(e) => setSql(e.target.value)}
          placeholder="请在此处填写SQL"
          className="sql-input"
        />
        <Button type="primary" onClick={handleSubmit} className="submit-button">
          提交
        </Button>
        <Button type="primary" onClick={showAnswer} className="submit-button">
          查看答案
        </Button>
      </div>
      <Modal
        // title="答题结果"
        title={`答题结果：${modalContent.result}`}
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalOk}
      >
        {/*<p>答题结果：{modalContent.result}</p>*/}
        <div>
          <p>您的输出：</p>
          {renderContent(modalContent.output)}
        </div>
      </Modal>

      <Modal
        title={`参考答案`}
        visible={answerModalVisible}
        onOk={handleAnswerModalOk}
        onCancel={handleAnswerModalOk}
      >
        <div>
          <pre>{answerModalContent.content}</pre>
        </div>
      </Modal>

    </Card>
  );
};

const renderContent = (content) => {
//   content = `
// 请写一个SQL语句，查询出所有的用户信息。
//
// 表名：users
//
// | id | name    |  gender | birth_date |
// |----|---------|---------| ---------- |
// | 1  | 蛐蛐     |  女     | 2006-02-16 |
// | 2  | 毛毛     |  男     | 2000-03-21 |
// | 3  | 困困     |  女     | 2001-02-14 |
// | 4  | 米粒     |  男     | 1999-08-18 |
//
// 示例输出：
//
// | id | name | gender | birth_date |
// |----|------|--------|------------|
// | 1  | 蛐蛐 | 女     | 2006-02-16 |
// | 2  | 毛毛 | 男     | 2000-03-21 |
// | 3  | 困困 | 女     | 2001-02-14 |
// | 4  | 米粒 | 男     | 1999-08-18 |
// `;
  const lines = content.split('\n');
  const elements = [];
  let currentTable = [];
  let inTable = false;
  const tableRegex = /^\|.*\|$/;

  lines.forEach((line, index) => {
    if (tableRegex.test(line.trim())) {
      inTable = true;
      currentTable.push(line.trim());
    } else {
      if (inTable) {
        inTable = false;
        elements.push(renderTable(currentTable));
        currentTable = [];
      }
      if (line.trim() !== '') {
        elements.push(<p key={index}>{line.trim()}</p>);
      }
    }
  });

  if (currentTable.length > 0) {
    elements.push(renderTable(currentTable));
  }

  return <div>{elements}</div>;
};

const renderTable = (lines) => {
  if (lines.length < 2) {
    return null;
  }

  const headerLine = lines[0].split('|').filter(cell => cell.trim());
  const columns = headerLine.map((header, index) => ({
    title: header,
    dataIndex: index,
    key: index,
  }));

  const dataSource = lines.slice(2).map((line, index) => {
    const cells = line.split('|').filter(cell => cell.trim());
    const dataObject = cells.reduce((obj, cell, i) => {
      obj[i] = cell;
      return obj;
    }, {});
    return { key: index, ...dataObject };
  });

  return <Table dataSource={dataSource} columns={columns} pagination={false} />;
};

export default QuestionDetail;
