import React, { useState, useEffect } from 'react';
import { Layout, Spin, message } from 'antd';
import axios from '../axiosConfig';
import QuestionList from '../components/QuestionList';
import QuestionDetail from '../components/QuestionDetail';
import './QuizPage.css';

const { Header, Sider, Content } = Layout;

const QuizPage = () => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    axios.get('/api/questions')
      .then(response => {
        setQuestions(response.data);
        setLoading(false);
      })
      .catch(error => {
        message.error('Failed to load questions');
        setLoading(false);
      });
  }, []);

  const handleSelectQuestion = (id) => {
    axios.get(`/api/questions/${id}`)
      .then(response => {
        setSelectedQuestion(response.data);
      })
      .catch(error => {
        message.error('Failed to load question details');
      });
  };

  const handleSubmitAnswer = (id, sql) => {
    axios.post(`/api/questions/${id}/submit`, { sql })
      .then(response => {
        const { is_correct, answer, output } = response.data.data;
        if (is_correct) {
          message.success('Correct answer!');
        } else {
          message.error('Wrong answer. Try again.');
        }
        console.log('Expected:', answer);
        console.log('Your output:', output);
      })
      .catch(error => {
        message.error('Failed to submit answer');
      });
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="header">
        <div className="logo">区区SQL在线练习平台</div>
      </Header>
      <Layout>
        <Sider width={300} className="site-layout-background" theme="light">
          {loading ? <Spin /> : <QuestionList questions={questions} onSelect={handleSelectQuestion} />}
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {selectedQuestion ? (
              <QuestionDetail question={selectedQuestion} onSubmit={handleSubmitAnswer} />
            ) : (
              <div className="welcome-message">请从题目列表中选择一个题目</div>
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default QuizPage;
